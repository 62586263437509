import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Link from '@nubank/nuds-web/components/Link/Link';
// import Grid from '@nubank/nuds-web/components/Grid/Grid';
import Grid from '@nubank/nuds-web/components/DeprecatedGrid/DeprecatedGrid';
import Box from '@nubank/nuds-web/components/Box/Box';
import Collapse from '@nubank/nuds-web/components/Collapse/Collapse';
import ReactHtmlParser from 'react-html-parser'; 

// import Section from 'components/structure/Section';
import Block from './components/structure/Block/Block.js';

const questions = [
{
  intlKey: 'faq1',
  question: 'Se eu não me identificar como pessoa negra, posso me inscrever?',
  answer: 'Esse é um processo de recrutamento exclusivo para pessoas negras. Caso você não se identifique como parte dessa população, confira nossas demais oportunidades na página de carreiras do Nubank: <a color="primary.default" href="https://nubank.com.br/carreiras/"><i>www.nubank.com.br/carreiras</i></a>' 
},
{
  intlKey: 'faq2',
  question: 'Por que o Nubank está fazendo um processo dedicado às pessoas negras?',
  answer: 'Queremos acelerar a construção de um ambiente mais diverso e inclusivo, pois sabemos que a área de análise de negócios é um campo de atuação em que a presença de pessoas negras ainda é sub-representada, e nos comprometemos a ajudar mudar essa realidade contratando pelo menos 2 mil pessoas negras no Nubank até 2025.'
},
{
  intlKey: 'faq3',
  question: 'Quais as etapas e datas do processo?',
  answer: 'As inscrições para o nosso processo vão até o dia 15/02. A primeira fase será um teste online que será enviado no dia 14/03. A segunda fase consiste em 3 entrevistas de business case e enquanto a terceira e última fase consiste em uma entrevista com o time de People and Culture. Todas as entrevistas são eliminatórias e acontecerão no período entre 30/03 e 12/04.'
},
{
  intlKey: 'faq4',
  question: 'Como funcionam as entrevistas de case?',
  answer: 'Nós temos três entrevistas de case, nas quais você terá o desafio de resolver um problema de negócio, que acontecem consecutivamente no mesmo dia. Você irá passar pelas duas primeiras, ter um intervalo de 30 minutinhos e retornar para a terceira. Cada entrevista tem duração de 1h e todas são eliminatórias, ou seja, você receberá um feedback se avançará para a próxima ou não ao final de cada uma. Não se preocupe, iremos enviar um email com todos os detalhes e possibilidades de dia/horários para que você possa se programar com bastante antecedência.'
},
{
  intlKey: 'faq5',
  question: 'Não recebi o teste online, o que eu faço?',
  answer: 'Os testes serão enviados dia 14 de março. Fique sempre atento ao spam e à aba de promoções no seu email. Caso não tenha recebido após esta data, entre em contato pelo email <a color="primary.default" href="mailto:nuprocessoseletivoba@nubank.com.br"><i>nuprocessoseletivoba@nubank.com.br</i></a>'
},
{
  intlKey: 'faq6',
  question: 'Quando saberei se meu perfil foi aprovado entre as etapas?',
  answer: 'Enviaremos um email comunicando a sua aprovação ou não no final de cada fase e explicando os próximos passos. O resultado do teste online será enviado a partir do dia 22/03, e o resultado das entrevistas de case até o dia 05/04.'
},
{
  intlKey: 'faq7',
  question: 'O processo terá alguma fase presencial?',
  answer: 'Não, todas serão online.'
},
{
  intlKey: 'faq8',
  question: 'Minha internet/energia caiu no meio do meu teste/meu computador travou na hora do teste/meu teste deu erro, o que eu faço?',
  answer: 'Por favor, entre em contato com <a color="primary.default" href="mailto:nuprocessoseletivoba@nubank.com.br"><i>nuprocessoseletivoba@nubank.com.br</i></a> para reportar o seu problema assim que conseguir. Entraremos em contato o mais rápido possível para explicar os próximos passos.'
},
{
  intlKey: 'faq9',
  question: 'Posso ser contratado como efetivo/full time sem ter me formado?',
  answer: 'Sim! Dependendo apenas da sua disponibilidade de trabalhar 40h semanais, como um efetivo regular.'
},
{
  intlKey: 'faq10',
  question: 'Em qual área eu vou atuar como Analista de Negócios?',
  answer: 'Sabemos que a curiosidade é grande, porém essa informação só é definida no final do processo. Assim que todas as alocações forem definidas, iremos compartilhar com as pessoas aprovadas.'
},
{
  intlKey: 'faq11',
  question: 'Todas as vagas são para o Brasil?',
  answer: 'Sim. Por enquanto, todas as nossas vagas deste processo seletivo são para São Paulo, onde fica nosso escritório principal.'
},
{
  intlKey: 'faq12',
  question: 'É necessário ter Inglês fluente para participar do processo?',
  answer: 'Não. Entretanto, cabe ressaltar que o Nubank é uma empresa internacional com colaboradores de mais de 40 nacionalidades diferentes, e portanto o inglês é muito utilizado na comunicação entre diferentes equipes. Sendo assim, incentivamos que todas as pessoas desenvolvam seu inglês dentro da empresa através do nosso programa de idiomas, o Nu Language.'
},
{
  intlKey: 'faq13',
  question: 'As entrevistas de case serão em Inglês?',
  answer: 'Não, as entrevistas de case serão em português. Apenas durante a entrevista final com o time de RH, haverá um diálogo simples em inglês para avaliar sua capacidade de compreensão e comunicação em situações cotidianas do trabalho.'
},
{
  intlKey: 'faq14',
  question: 'Eu preciso saber programação para me candidatar?',
  answer: 'Não. Programação é uma ferramenta importante para o trabalho de um analista, e por isso esperamos que todas as pessoas estejam dispostas e interessadas em aprender, entretanto consideramos que essas habilidades serão desenvolvidas dentro do Nubank com o auxílio dos nossos treinamentos.'
}

];

var stringToHTML = function (str) {
  var parser = new DOMParser();
  var doc = parser.parseFromString(str, 'text/html');
  return doc.body;
}
var parser = new DOMParser();

class SectionFAQ extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      currentStep: 1,
    };
  }

  handleChangeCarouselStep = step => {
    this.setState(() => ({
      currentStep: step,
    }));
  };

  render() {
    const { currentStep } = this.state;

    return (
      <Grid
        row
        id="faq"
        tag="section"
        spacingHorizontal={{
          xs: '2x',
          md: '4x',
          lg: '6x',
          }}
          spacingTop={{ xs: '16x', md: '16x', lg: '16x' }}
          >
          <Grid
            col={{ xs: 12, lg: 10 }}
            offset={{ lg: 1 }}
            Top={{ xs: '6x', lg: '8x' }}
            >
            <Typography
              variant="heading3"
              color="black"
              colorVariant="default"
              gutterBottom={2}
              >
              Perguntas frequentes
            </Typography>
          </Grid>


          {questions.map(question => (
            // <div >
            <Grid
              key={question.intlKey}
              col={{ xs: 12, lg: 10 }}
              offset={{ lg: 1 }}
              spacingVertical={{ xs: '6x', lg: '8x' }}
              >
              <Box
                gridGap={{
                  sm: '20px',
                  md: '1em',
                  lg: '20px'
                  }}>
                  <Collapse>
                    <Collapse.Header>
                      <Typography
                        variant="heading4"
                        color="black"
                        colorVariant="light"
                        >
                        {question.question}
                      </Typography>
                    </Collapse.Header>

                    <Collapse.Body>
                      <Typography
                        id={question.intlKey}
                        variant="paragraph1"
                        color="black"
                        colorVariant="light"
                        intlValues={{
                          strong: (...text) => (
                            <Typography
                              tag="span"
                              variant="subtitle1"
                              strong
                              >
                              {text}
                              </Typography>
                              ),
                          }}
                          >
                          { ReactHtmlParser (question.answer) } 
                          
                        </Typography>
                      </Collapse.Body>
                    </Collapse>
                  </Box>
                  </Grid>
                // </div>
                  ))}
          </Grid>
          );
  }
}

export default SectionFAQ;

import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Link from '@nubank/nuds-web/components/Link/Link';
// import Grid from '@nubank/nuds-web/components/Grid/Grid';
import Grid from '@nubank/nuds-web/components/DeprecatedGrid/DeprecatedGrid';
import Box from '@nubank/nuds-web/components/Box/Box';

// import Section from 'components/structure/Section';
import Block from './components/structure/Block/Block.js';
import Carousel from './components/Carousel/Carousel.js';

const quotes = [
{
  intlKey: '2',
  name: 'Sofia Guimaraes',
  txt: 'Entrei no Nubank no final de 2019 como estagiária de verão. Quando aceitei o estágio, esperava encontrar um ambiente colaborativo, que me desafiasse a crescer profissionalmente e, acima de tudo, engajado em criar um impacto positivo na sociedade. Não estava enganada. Cada projeto é um novo desafio, no qual é sempre possível aprender uma nova ferramenta, uma nova análise ou, até mesmo, uma nova forma de pensar. Como BA do Nubank, tenho a chance de trabalhar em um time multifuncional, no qual pessoas com diversas competências se complementam para chegar no melhor produto que podemos oferecer para nossos e nossas clientes. Sou muito feliz por fazer parte deste time!',
  position: 'Business Analyst - Cartão de Crédito'

},
{
  intlKey: '1',
  name: 'Cintia Domiciano',
  txt: 'Eu entrei no Nubank como estagiária depois de uma palestra que assisti na minha faculdade. Desde aquele dia eu já sabia que gostaria muito de trabalhar aqui por ser um lugar muito diferente das empresas tradicionais nas quais eu tive experiência. O que mais me chamou a atenção na época —  e continua sendo meu ponto favorito no Nubank — foram as pessoas, que fazem daqui um ambiente ótimo e sempre têm disposição para ajudar todo mundo. Tem sido uma jornada incrível e de muito aprendizado que eu recomendo para qualquer pessoa que esteja procurando novos desafios!',
  position: 'Business Analyst - Cartão de Crédito'
},
{
  intlKey: 'Franklin',
  name: 'Franklin Maia',
  txt: 'Comecei a minha carreira no Nu há aproximadamente 6 anos como Analista de Negócios e, desde então, venho trabalhando perto das áreas de crédito. Pessoalmente, eu gosto muito de lidar com assuntos altamente complexos analiticamente e a área de crédito é um campo muito fértil nesse tema. Inclusive, esse é um dos grandes prazeres que eu tenho em trabalhar no Nubank: conseguir trabalhar em assuntos complexos, com tecnologia e técnicas de ponta e sem um viés dogmático do que pode ou não pode ser feito.',
  position: 'General Manager - Empréstimo Pessoal'
},

{
  intlKey: 'Fernanda',
  name: 'Fernanda Leal',
  txt: 'Trabalhar no Nubank supera quaisquer expectativas. É um ambiente onde todos os dias somos desafiados a dar nosso melhor e, ao mesmo tempo, temos todo o suporte para conseguirmos nos desenvolver — seja por treinamentos, feedbacks recorrentes ou pelo constante contato com os colegas de trabalho. Isso só é possível porque os valores da empresa realmente estão presentes no nosso dia a dia, deixando o clima extremamente colaborativo e nos permitindo questionar constantemente as decisões já tomadas. Fico feliz por estar vivendo e contribuindo com essa revolução roxa.',
  position: 'Business Analyst - Cartão de Crédito'
},

{
  intlKey: 'Manoel',
  name: 'Manoel Bonfim',
  txt: 'Trabalho no Nubank há 1 ano e 9 meses e, vindo de outra empresa, o que mais me surpreendeu foi o ambiente receptivo e colaborativo da empresa. Como Analistas de Negócios, é esperado de nós trazer soluções para problemas complexos, utilizando dados para cada tomada de decisão. Isso é possível não só graças às ferramentas e tecnologias à nossa disposição, mas também aos colegas de diversas funções que sempre oferecem sugestões e nos ajudam a encontrar as melhores respostas.',
  position: 'Business Analyst - Cartão de Crédito'
},


];

class SectionTestimonials extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      currentStep: 1,
    };
  }

  handleChangeCarouselStep = step => {
    this.setState(() => ({
      currentStep: step,
    }));
  };

  render() {
    const { currentStep } = this.state;

    return (
      <Box
        tag="section"
        // paddingBottom={{ xs: '12x', md: '10x' }}
        alignItems= 'center'
          >



          <Box
            paddingHorizontal={{ xs: '1x', md: '12x', lg: 56 }}
            marginTop={{ xs: '8x', md: '12x', lg: '20x' }}
            display={{ lg: 'grid' }}
            gridTemplateColumns={{ lg: 'repeat(12,1fr)' }}
            gridColumnGap={{ lg: '8x' }}
            >
            <Box
              gridColumn={{ lg: '2 / 12' }}
              maxWidth={{ md: '700px', lg: 'none' }}
              marginHorizontal={{ md: 'auto' }}
              justify='center'
              alignItems='center'
              >
              <Typography
                variant="heading3"
                // marginBottom={{ xs: '6x' }}
                // marginHorizontal={{ md: 'auto' }}
                // maxWidth={{ xs: '12ch', lg: 'none' }}
                textAlign='center'
                >
                Depoimentos
              </Typography>

              <Block blockWidth="100%" textAlign="center" justifyContent="center">

              <Carousel
                textAlign= 'center'
                currentStep={currentStep}
                onStepChanged={this.handleChangeCarouselStep}
                // textAlign="center" justifyContent="center"
                >
                {quotes.map(quote => (
                  <div key={quote.intlKey}>
                    <Typography
                      variant="subtitle2"
                      tag="p"
                      gutterBottom={2}
                      textAlign="center" justifyContent="center"
                      >
                      <i>"{quote.txt}"</i>

                      {/* <FormattedMessage id={`${quote.intlKey}`}> */}
                      {/*   {/* {quote.txt} */} 
                      {/*   {${quote.txt} => `"${txt}"`} */}
                      {/* </FormattedMessage> */}
                    </Typography>
                    <Typography variant="subtitle1"strong color="primary" textAlign="center" justifyContent="center">
                      {quote.name}
                    </Typography>
                    <Typography variant="paragraph2"  textAlign="center" justifyContent="center">
                      {quote.position}
                    </Typography>

                    </div>
                    ))}
              </Carousel>

              </Block>
            </Box>
          </Box>
          </Box>

          );
  }
}

export default SectionTestimonials;

import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Link from '@nubank/nuds-web/components/Link/Link';
// import Grid from '@nubank/nuds-web/components/Grid/Grid';
import Grid from '@nubank/nuds-web/components/DeprecatedGrid/DeprecatedGrid';
import Box from '@nubank/nuds-web/components/Box/Box';

// import Section from 'components/structure/Section';
import Block from './components/structure/Block/Block.js';
import Carousel from './components/Carousel/Carousel.js';

const modules = [
{
  intlKey: '1',
  name: 'Cálculo e lógica',
  txt: 'Este módulo abordará temas matemáticos essenciais para ajudar as pessoas candidatas a passarem pela etapa do teste online e questões quantitativas da etapa de case.',
},
{
  intlKey: '2',
  name: 'Negócios e finanças',
  txt: 'Conceitos de negócios e do mercado financeiro para contextualização do cenário da empresa. Esse conhecimento será importante para as questões qualitativas da etapa de case.',
},
{
  intlKey: '3',
  name: 'Resolução de problemas',
  txt: 'Esse módulo vai abordar questões relacionadas a como definir um problema, elaborar hipóteses e agir diante de um case. Esse conhecimento será importante para a etapa de cases.',
},
{
  intlKey: '4',
  name: 'Comunicação',
  txt: 'No quarto e último módulo do Bootcamp, o foco é a comunicação. Nele, apresentamos dicas de resolução de problemas que poderão ajudar a estruturar sua linha de raciocínio e trabalhar a narrativa de resolução.',
}
];

class SectionBootcampModules extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      currentStep: 1,
    };
  }

  handleChangeCarouselStep = step => {
    this.setState(() => ({
      currentStep: step,
    }));
  };

  render() {
    const { currentStep } = this.state;

    return (
      <Box
        tag="section"
        // paddingBottom={{ xs: '12x', md: '10x' }}
        alignItems= 'center'
          >

          <Box
            paddingHorizontal={{ xs: '1x', md: '12x', lg: 56 }}
            // marginTop={{ xs: '8x', md: '12x', lg: '20x' }}
            display={{ lg: 'grid' }}
            gridTemplateColumns={{ lg: 'repeat(12,1fr)' }}
            gridColumnGap={{ lg: '8x' }}
            >
            <Box
              gridColumn={{ lg: '2 / 12' }}
              maxWidth={{ md: '700px', lg: '700px' }}
              marginHorizontal={{ md: 'auto' }}
              justify='center'
              alignItems='center'
              >
              <Typography
                variant="subtitle1"
                strong
                // marginBottom={{ xs: '6x' }}
                // marginHorizontal={{ md: 'auto' }}
                // maxWidth={{ xs: '12ch', lg: 'none' }}
                textAlign='center'
                gutterBottom={1.5}
                >
                Confira os Módulos:
              </Typography>

              <Block
              // paddingBottom="10x"
               blockWidth="100%" textAlign="center" justifyContent="center">

              <Carousel
                textAlign= 'center'
                currentStep={currentStep}
                onStepChanged={this.handleChangeCarouselStep}
                // textAlign="center" justifyContent="center"
                >
                {modules.map(module => (
                  <div key={module.intlKey}>
                    <Typography 
                      variant="heading4" strong 
                      color="primary" 
                      textAlign="center" 
                      gutterBottom={1}
                      justifyContent="center">
                      {module.name}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      // tag="p"
                      // gutterBottom={1}
                      textAlign="center" justifyContent="center"
                      >
                      {module.txt}
                      {/* <FormattedMessage id={`${quote.intlKey}`}> */}
                      {/*   {/* {quote.txt} */} 
                      {/*   {${quote.txt} => `"${txt}"`} */}
                      {/* </FormattedMessage> */}
                    </Typography>
                    
                    {/* <Typography variant="paragraph2"  textAlign="center" justifyContent="center"> */}
                    {/*   {quote.position} */}
                    {/* </Typography> */}

                    </div>
                    ))}
              </Carousel>

              </Block>
            </Box>
          </Box>
          </Box>

          );
  }
}

export default SectionBootcampModules;

import styled, { css } from 'styled-components';

export const CarouselItemContent = styled.div`
  flex-grow: 1;
  flex-shrink: 0;
  position: relative;
  transition: 0.75s cubic-bezier(0.23, 1, 0.32, 1);
  flex-basis: ${({ flexBasis }) => flexBasis};
  ${({ selected }) => selected
    && css`
      z-index: 3;
    `};
`;
import styled, { css } from 'styled-components';
import { breakpointsMedia } from '@nubank/nuds-web/styles/breakpoints';
import Box from '@nubank/nuds-web/components/Box/Box';

// import { backgroundImage } from '@nubank/www-latam-commons/components/Image/Image';
import { backgroundImage } from '@nubank/nuds-web/components/Image/Image';

export const StyledBox = styled(Box)`
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top left;
  background-image:  'img/2-woman-working@1x.jpg';
  ${breakpointsMedia({
    md: css`
      min-height: calc(100vh - 4rem);
      background-position: bottom left;
    `,
    lg: css`
      min-height: auto;
      background-position: bottom center;
    `,
  })}
`;

StyledBox.displayName = 'StyledBox';

export const StyledCenteredBox = styled(Box)`
  text-align: center;
`;

StyledCenteredBox.displayName = 'StyledCenteredBox';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// import { themeSpace } from 'styles/themeUtils';

export const CarouselWrapper = styled.div`
  overflow: hidden;
  position: relative;
  max-width: 100vw;
`;

CarouselWrapper.displayName = 'CarouselWrapper';

export const CarouselContainer = styled.div`
  display: flex;
  position: relative;
  transition: ${props => (props.animation ? 'transform 0.75s cubic-bezier(0.23, 1, 0.32, 1)' : 'none')};
  transform: translateX(${p => p.transform || 0});
  will-change: contents;
`;

CarouselContainer.displayName = 'CarouselContainer';

CarouselContainer.propTypes = {
  animation: PropTypes.bool.isRequired,
  transform: PropTypes.string.isRequired,
};

export const SwipeableArea = styled.div`
  width: 100%;
  text-align: "center";
`;

SwipeableArea.displayName = 'SwipeableArea';

export const ControlsContainer = styled.div`
  padding: 1.5;
`;

ControlsContainer.displayName = 'ControlsContainer';

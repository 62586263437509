import logo from './logo.svg';
import './App.css';
// import Grid from '@nubank/nuds-web/components/Grid/Grid';
import Grid from '@nubank/nuds-web/components/DeprecatedGrid/DeprecatedGrid';

import Typography from '@nubank/nuds-web/components/Typography/Typography';
import NuLogo from '@nubank/nuds-web/components/NuLogo/NuLogo';
import Link from '@nubank/nuds-web/components/Link/Link';
import NuDSProvider from '@nubank/nuds-web/components/NuDSProvider/NuDSProvider';
import { nuDSColor } from '@nubank/nuds-web/styles/themeUtils'
import Box from '@nubank/nuds-web/components/Box/Box';
import Button from '@nubank/nuds-web/components/Button/Button';
import Image from '@nubank/nuds-web/components/Image/Image';
import React from 'react';
import Collapse from '@nubank/nuds-web/components/Collapse/Collapse';
import YouTube from 'react-youtube-embed'
// import Carousel from 'components/Carousel/Carousel';
import Footer from '@nubank/nuds-web/patterns/Footer/Footer';

import { StyledCenteredBox } from './styles/Box';

import 'react-sticky-header/styles.css';
import StickyHeader from 'react-sticky-header';

import SectionTestimonials from './SectionTestimonials';
import SectionFAQ from './SectionFAQ';
import SectionBootcampModules from './SectionBootcampModules';

function App() {
	return (

		<NuDSProvider>

			{/* HEADER  LOGO + BOTAO */}
			<StickyHeader
  			  // This is the sticky part of the header.
				header={
					<Box
						tag="header"
						flex="1 100%"
						padding="1px"
						backgroundColor="white.default"
						boxShadow="rgb(27 27 27 / 20%) 0px 1px 5px;"
						// borderBottom="1px solid rgb(27 27 27 / 30%)"
						>
						<Grid
							row
							alignItems="center"
							justify="space-between"
							spacingHorizontal={{
								xs: '4x',
								md: '8x',
								lg: '1x',
								}}
								>
								<Grid
									col={{
										xs: 2,
										sm: 2,
										lg: 1,
										xl: 1,
										}}
										offset={{
											xs: 0,
											sm: 0,
											lg: 1,
											xl: 1,
											}}
											>

											<NuLogo
											// height='40'
												justify="right"
												variant="primary"
											/>
										</Grid>

										{/*.      GRID DO BOTAO DE INSCRICAO.    */}
										<Grid
											offset={{
												xs: 1,
												sm: 1,
												lg: 1,
												xl: 1,
												}}

												col=
												{{
													xs: 'auto',
													sm: 'auto',
													lg: 3,
													xl: 3,
													}}
													spacingRight={{
														xs:"2x",
														sm:"4x"
														}}

														>
														<Button
															variant="contained"
															styleVariant="primary"
															iconProps={{ name: 'arrow-right', title: 'Candidate-se' }}
															id="candidateButton"
															onClick={(e) => {
																e.preventDefault();
																window.open("https://grnh.se/b7791ab61us", "_blank");
																}}
																>
																Candidate-se
															</Button>

														</Grid>
													</Grid>
													</Box>
													}
													>
												</StickyHeader>

												{/*      Division line      */}
												<Box
													display="grid"
													paddingHorizontal={{ xs: '4x', md: '6x', lg: '12x' }}
													paddingTop={{xs: '13vh', sm: '12vh', lg: '20vh'}}
													paddingBottom={{ xs: '12x', lg: '10x' }}
													minHeight={{ md: '70vh' }}

													gridColumnGap={{ xs: '4x', md: '8x', lg: '12x' }}
													gridRowGap={{ xs: '8x', md: '12x', lg: '10x' }}
													alignItems={{
														md: 'flex-end',
														lg: 'center' }}

														backgroundColor="primary.default"
														backgroundImage='/img/Banner 2022.png'
														backgroundSize="cover"
														backgroundPosition="center"
														>
														<Grid
															col="fill"
															spacingRight="4x"
															spacingLeft="2x"
															>
															<Typography
																textAlign="left"
																variant="heading1"
																color="white"
																>
																Processo Seletivo <br/> Analista de Negócios
															</Typography>

															<Typography
																textAlign="left"
																variant="heading4"
																gutterBottom={1.5}
																color="white"
																>
																Exclusivo para Pessoas Negras
															</Typography>

															<Button
																variant="contained"
																styleVariant="white"
																iconProps={{ name: 'arrow-right', title: 'Candidate-se' }}
																id="candidateButton"
																onClick={(e) => {
																	e.preventDefault();
																	window.open("https://grnh.se/b7791ab61us", "_blank");
																	}}
																	>
																	Candidate-se
																</Button>
																

																<Box
																	paddingTop={{xs: '5vh', lg: '20vh'}}

																	paddingLeft='10px'
																	alignSelf='flex-end'
																	>
																	<NuLogo
																		variant="white"
																	/>
																</Box>
															</Grid>
														</Box>


														{/* O QUE é O CAMPUS HIRING */}

														<Grid
															row
															coll="fill"
															tag="header"
															spacingVertical={{
																xs: '1x',
																md: '1x',
																lg: '1x',
																}}
																backgroundColor="white.default"/>

																<Grid
																	row
																	tag="section"
																	alignItems="center"
																	spacingHorizontal={{
																		xs: '4x',
																		md: '8x',
																		lg: '24x',
																		}}
																		spacingVertical={{
																			xs: '6x',
																			md: '6x',
																			lg: '12x',
																			}}
																			>

																			<Grid
																				offset={{ lg: 1.5}}
																				col={{
																					xs: 12,
																					lg: 9,
																					xl: 9,
																					}}
																					>
																					<Typography
																						variant="heading3"
																						gutterBottom={1.5}
																						color="primary"
																						textAlign="center"
																						>
																						O Processo Seletivo de BA's no Nubank e o nosso compromisso
																					</Typography>

																					<Typography
																						variant="paragraph1"
																						gutterBottom={1.5}
																						textAlign="center"
																						>
																						Analistas de Negócio, ou Business Analysts (BA's), possuem um processo seletivo especial no Nubank. Com o objetivo de receber pessoas de diferentes perfis e trajetórias, lançamos anualmente este projeto para recrutar as pessoas que serão responsáveis por guiar nossas decisões mais estratégicas. <br/> Em 2021, na última edição, foram mais de 80 contratações! 
																					</Typography>

																					<Typography
																						variant="subtitle1"
																						gutterBottom={1.5}
																						textAlign="center"
																						>
																						Comprometidos com nosso objetivo de tornar o Nubank um ambiente cada vez mais diverso, lançamos em 2022 uma edição <Typography tag="span" variant="subtitle1" strong>exclusiva para pessoas negras</Typography>.
																					</Typography>
																					<Typography
																						variant="paragraph1"
																						gutterBottom={1.5}
																						textAlign="center"
																						>
																						Acreditamos no <Typography tag="span" variant="paragraph1" strong>poder da diversidade</Typography> e queremos construir times cada vez mais fortes! Estamos fazendo progresso, mas o trabalho ainda está no começo.
																					</Typography>
																					<Typography
																						variant="paragraph1"
																						strong
																						textAlign="center"
																						>
																						<Link
																							variant="action"
																							gutterTop={1.5}
																							color="primary"
																							href="https://blog.nubank.com.br/compromisso-do-nubank-contratar-2-mil-pessoas-negras-ate-2025/"
																							>
																							Descubra mais sobre nosso compromisso com a diversidade
																						</Link>

																					</Typography>
																				</Grid>
																			</Grid>

																			{/*      Division line      */}

																			<Grid
																				row
																				coll="fill"
																				tag="header"
																				spacingVertical={{
																					xs: '1x',
																					md: '1x',
																					lg: '1x',
																					}}
																					backgroundColor="primary.default"/>

																					<Grid
																						row
																						coll="fill"
																						tag="header"
																						spacingVertical={{
																							xs: '1x',
																							md: '1x',
																							lg: '1x',
																							}}
																							backgroundColor="white.default"/>




																							{/*    SECTION DE O QUE FAZ UM BA.   */}
																							<Grid
																								row
																								tag="section"
																								alignItems="center"
																								spacingHorizontal={{
																									xs: '4x',
																									md: '8x',
																									lg: '18x',
																									}}
																									spacingVertical={{
																										xs: '6x',
																										md: '6x',
																										lg: '12x',
																										}}

																										>
																										{/*     GRID DO VIDEO iFrame YT  >>>TODO: auto scale  */}
																										<Grid
																											spacingVertical={{ xs: '6x', lg: '4x' }}
																											col={{
																												xs: 12,
																												lg: 5,
																												xl: 6,
																												}}
																												spacingHorizontal={{
																													xs: '4x',
																													md: '8x',
																													lg: '18x',
																													}}
																													>

																													<YouTube id='fWj5rsU-7Sk' />  
																													{/* <iframe id='video' width="100%" height="100%" src="https://www.youtube.com/embed/SltbtJfFZGM" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
																												</Grid>

																												<Grid
																													offset={{ lg: 0 }}

																													col={{
																														xs: 12,
																														lg: 6,
																														xl: 6,
																														}}
																														>

																														<Typography
																															variant="heading3"
																															gutterBottom={1.5}
																															>
																															O que fazem Analistas de Negócios no Nubank?
																														</Typography>
																														<Typography
																															variant="paragraph1"
																															gutterBottom={1.5}
																															>
																															Analistas de negócios são pessoas que tomam decisões baseadas em dados. Através de sua bagagem analítica, conseguem elaborar uma estrutura para resolução de um problema e traduzir grandes quantidades de números e dados em tomadas de decisão estratégicas.
																														</Typography>
																														<Link
																															variant="action"
																															href="https://blog.nubank.com.br/analista-business-ba-nubank/"
																															>
																															Descubra mais no nosso Blog!
																														</Link>

																													</Grid>


																												</Grid>

																												{/* </Grid> */}

																												{/*      Division line      */}

																												<Grid
																													row
																													coll="fill"
																													tag="header"
																													spacingVertical={{
																														xs: '1x',
																														md: '1x',
																														lg: '1x',
																														}}
																														backgroundColor="primary.default"/>
																														{/*Seção de perfil do candidato*/}
																														<Grid
																															row
																															tag="section"
																															alignItems="center"
																															spacingHorizontal={{
																																xs: '4x',
																																md: '8x',
																																lg: '24x',
																																}}
																																spacingVertical={{
																																	xs: '6x',
																																	md: '6x',
																																	lg: '12x',
																																	}}
																																	>

																																	<Grid
																																		offset={{ lg: 1.5}}
																																		col={{
																																			xs: 12,
																																			lg: 9,
																																			xl: 9,
																																			}}
																																			>
																																			<Typography
																																				variant="heading3"
																																				gutterBottom={1.5}
																																				color="primary"
																																				textAlign="center"
																																				>
																																				Qual perfil estamos buscando?
																																			</Typography>

																																			<Typography
																																				variant="heading4"
																																				gutterBottom={1.5}
																																				textAlign="center"
																																				>
																																				Pessoas que se identificam como negras, com capacidade analítica e noção básica de negócio, profissionais em início ou transição de carreira com vontade de aprender. Não é necessário experiência na área, e nem formação superior completa.
																																			</Typography>

																																		</Grid>

																																	</Grid>
																																	{/*      Division line      */}

																																	<Grid
																																		row
																																		coll="fill"
																																		tag="header"
																																		spacingVertical={{
																																			xs: '1x',
																																			md: '1x',
																																			lg: '1x',
																																			}}
																																			backgroundColor="primary.default"/>																				

																																			{/*      GRID TIMELINE.      */}
																																			<Grid
																																				row
																																				tag="section"
																																				alignItems="center"
																																				spacingHorizontal={{
																																					xs: '4x',
																																					md: '8x',
																																					lg: '12x',
																																					}}
																																					spacingVertical={{
																																						xs: '6x',
																																						md: '6x',
																																						lg: '16x',
																																						}}
																																						backgroundColor="white.dark" 
																																						>

																																						<Grid
																																							col="fill"
																																							offset={{
																																								xs:0,
																																								lg: 1
																																								}}

																																								>
																																								<Typography
																																									variant="heading3"
																																									gutterBottom={2.5}
																																									gutterTop={2.5}
																																									>
																																									Datas importantes:
																																								</Typography>

																																								<Grid
																																									row
																																									alignItems="flex-start" >

																																									<Grid
																																										justifyContent="center"
																																										col="fill"
																																										>
																																										<Box 
																																											display="flex" flexDirection="column" justifyContent="center" alignItems="center"
																																											>

																																											<Button
																																												variant="contained"
																																												styleVariant="primary"
																																												>1</Button>

																																											</Box>
																																											<Typography
																																												variant="heading3"
																																												textAlign='center'
																																												color='primary'
																																												>
																																												Inscrições
																																											</Typography>
																																											<Typography
																																												variant="paragraph1"
																																												gutterBottom={1.5}
																																												textAlign='center'
																																												color='black'
																																												>
																																												1 Fev a<br/> 15 Fev
																																											</Typography>

																																										</Grid>
																																										<Grid
																																											justifyContent="center"
																																											col="fill"
																																											>
																																											<Box 
																																												display="flex" flexDirection="column" justifyContent="center" alignItems="center"
																																												>
																																												<Button
																																													variant="contained"
																																													styleVariant="primary"
																																													>2</Button>
																																												</Box>
																																												<Typography
																																													variant="heading3"
																																													textAlign='center'
																																													color='primary'
																																													>
																																													Bootcamp
																																												</Typography>
																																												<Typography
																																													variant="paragraph1"
																																													textAlign='center'
																																													color='black'
																																													>
																																													22 Fev a<br/> 29 Mar
																																												</Typography>
																																												<Typography
																																													variant="paragraph2"
																																													gutterBottom={1.5}
																																													textAlign='center'
																																													color='gray'
																																													>
																																													<i>*Etapa opcional</i>
																																												</Typography>

																																											</Grid>
																																											<Grid
																																												justifyContent="center"
																																												col="fill"
																																												>
																																												<Box 
																																													display="flex" flexDirection="column" justifyContent="center" alignItems="center"
																																													>

																																													<Button
																																														variant="contained"
																																														styleVariant="primary"
																																														>3</Button>
																																													</Box>
																																													<Typography
																																														variant="heading3"
																																														textAlign='center'
																																														color='primary'
																																														>
																																														Teste Online
																																													</Typography>
																																													<Typography
																																														variant="paragraph1"
																																														gutterBottom={1.5}
																																														textAlign='center'
																																														color='black'
																																														>
																																														14 Mar a<br/> 20 Mar
																																													</Typography>

																																												</Grid>
																																												<Grid
																																													justifyContent="center"
																																													col="fill"
																																													>
																																													<Box 
																																														display="flex" flexDirection="column" justifyContent="center" alignItems="center"
																																														>
																																														<Button
																																															variant="contained"
																																															styleVariant="primary"
																																															>4</Button>
																																														</Box>
																																														<Typography
																																															variant="heading3"
																																															textAlign='center'
																																															color='primary'
																																															>
																																															Entrevistas
																																														</Typography>
																																														<Typography
																																															variant="paragraph1"
																																															gutterBottom={1.5}
																																															textAlign='center'
																																															color='black'
																																															>
																																															A partir de <br/>30 Mar
																																														</Typography>
																																													</Grid>
																																												</Grid>
																																											</Grid>

																																											<Grid
																																												col={{
																																													xs:0,
																																													lg: 1
																																													}}
																																												/>
																																											</Grid>

																																											{/*      Division line      */}

																																											<Grid
																																												row
																																												coll="fill"
																																												tag="header"
																																												spacingVertical={{
																																													xs: '1x',
																																													md: '1x',
																																													lg: '1x',
																																													}}
																																													backgroundColor="primary.default"/>



																																													<Box
																																														display="grid"
																																														paddingHorizontal={{ xs: '4x', md: '6x', lg: '12x' }}
																																														paddingTop={{ xs: '12x', lg: '12x' }}
																																														paddingBottom={{ xs: '12x', lg: '10x' }}
																																														minHeight={{ xs: '50vh', md: '70vh' }}
																																														gridColumnGap={{ xs: '4x', md: '8x' }}
																																														gridRowGap={{ xs: '8x', md: '10x', lg: '0x' }}
																																														gridTemplateColumns={{ lg: 'repeat(12,1fr)' }}

																																														alignItems={{
																																															md: 'flex-start',
																																															lg: 'center' }}

																																															backgroundColor="primary.default"
																																															backgroundImage='/img/Banner Bootcamp 2022.png'
																																															backgroundSize="cover"
																																															backgroundPosition="center"
																																															>

																																															<Grid
																																																col="fill"
																																																spacingRight="4x"
																																																spacingLeft="2x"
																																																>

																																																<Typography
																																																	textAlign="left"
																																																	variant="supertitle"
																																																	color="#4C0677"
																																																	>
																																																	Bootcamp
																																																</Typography>

																																																<Typography
																																																	textAlign="left"
																																																	variant="heading3"
																																																	gutterBottom={1.5}
																																																	color="primary"
																																																	>
																																																	Preparação para o<br/> processo seletivo
																																																</Typography>

																																																<br/>
																																																<br/>
																																																<Box
																																																	paddingLeft='10px'
																																																	gutterTop={2.5}
																																																	>
																																																	<NuLogo
																																																		variant="primary"
																																																	/>
																																																</Box>
																																															</Grid>
																																															



																																														</Box>



																																														<Box
																																															tag="section"
																																															paddingBottom={{ xs: '12x', md: '10x' }}
																																															alignItems={{
																																																md: 'flex-end',
																																																lg: 'center' }}
																																																>

																																																<Box
																																																	paddingHorizontal={{ xs: '6x', md: '12x', lg: 56 }}
																																																	marginTop={{ xs: '8x', md: '12x', lg: '20x' }}
																																																	display={{ lg: 'grid' }}
																																																	gridTemplateColumns={{ lg: 'repeat(12,1fr)' }}
																																																	gridColumnGap={{ lg: '8x' }}
																																																	>
																																																	<Box
																																																		gridColumn={{ lg: '3 / 11' }}
																																																		marginHorizontal={{ md: 'auto' }}
																																																		justify='center'
																																																		alignItems='center'
																																																		>
																																																		<Typography
																																																			variant="heading3"
																																																			marginBottom={{ xs: '6x' }}
																																																			marginHorizontal={{ md: 'auto' }}
																																																			maxWidth={{ xs: '12ch', lg: 'none' }}
																																																			textAlign={{ md: 'center' }}
																																																			>
																																																			O Bootcamp
																																																		</Typography>
																																																		<Typography
																																																			variant="paragraph1"
																																																			marginHorizontal={{ md: 'auto' }}
																																																			textAlign={{ md: 'center' }}
																																																			gutterBottom={1.5}
																																																			>

																																																			Para que as pessoas candidatas cheguem cada vez mais preparadas ao processo seletivo do Nubank, ofereceremos gratuitamente às 5 mil primeiras pessoas inscritas, um 
																																																			<Typography tag="span" variant="paragraph1" strong> Bootcamp de Negócios 100% online. </Typography>
																																																			Nele, as pessoas participantes poderão, ao longo de um mês, aprender mais sobre cálculo, lógica, conceitos de negócios e mercado financeiro, resolução de problemas, além de dicas práticas sobre comunicação.
																																																		</Typography>

																																																		<Typography
																																																			variant="paragraph2"
																																																			marginHorizontal={{ md: 'auto' }}
																																																			textAlign={{ md: 'center' }}
																																																			gutterBottom={1}
																																																			>
																																																			<i>A participação no Bootcamp é opcional, e no momento da inscrição você precisará preencher se tem interesse em participar. 
																																																			</i>
																																																		</Typography>
																																																		<Typography
																																																			variant="paragraph1"
																																																			marginHorizontal={{ md: 'auto' }}
																																																			textAlign={{ md: 'center' }}
																																																			gutterBottom={1}
																																																			>
																																																			Todo o conteúdo foi preparado e será apresentado por Analistas de Negócios do Nubank, que podem dar dicas valiosas!
																																																							<br></br>
																																																							<br></br>
																																																							<Link
																																																								variant="action"
																																																								color="primary"
																																																								textAlign='center'
																																																								justify='center'
																																																								href="https://blog.nubank.com.br/como-vai-funcionar-o-bootcamp-de-negocios-do-nubank/"

																																																								>
																																																								Leia mais sobre o Bootcamp de Negócios no nosso Blog
																																																							</Link>
																																																						</Typography>


																																																					</Box>
																																																				</Box>
																																																			</Box>

																																																			<SectionBootcampModules />

																																																			<Box
																																																				tag="section"
																																																				paddingTop={{ xs: '12x', md: '4x' }}
																																																				paddingBottom={{ xs: '12x', md: '10x' }}
																																																				alignItems={{
																																																					md: 'flex-end',
																																																					lg: 'center' }}
																																																					backgroundColor="#4C0677"
																																																					>

																																																					<Box
																																																						paddingHorizontal={{ xs: '6x', md: '12x', lg: 56 }}
																																																						marginTop={{ xs: '7x', md: '12x', lg: '15x' }}
																																																						display={{ lg: 'grid' }}
																																																						gridTemplateColumns={{ lg: 'repeat(12,1fr)' }}
																																																						gridColumnGap={{ lg: '8x' }}
																																																						>
																																																						<Box
																																																							gridColumn={{ lg: '3 / 11' }}
																																																							marginHorizontal={{ md: 'auto' }}
																																																							justify='center'
																																																							alignItems='center'
																																																							>
																																																							<Typography
																																																								color="white.dark"
																																																								variant="heading3"
																																																								marginBottom={{ xs: '6x' }}
																																																								marginHorizontal={{ md: 'auto' }}
																																																								textAlign={{ md: 'center' }}
																																																								>
																																																								Quer conhecer mais ainda sobre o time de BA's no Nubank?
																																																							</Typography>
																																																							<Typography
																																																								variant="paragraph1"
																																																								color="white.dark"
																																																								marginHorizontal={{ md: 'auto' }}
																																																								textAlign={{ md: 'center' }}
																																																								gutterBottom={1.5}
																																																								>

																																																								Participe do nosso bate-papo online no <Typography tag="span" variant="paragraph1" strong>dia 9 de Fevereiro às 19h.</Typography> Contaremos com a participação de Analistas de Negócios e de Recrutadores do Nubank que estarão ao vivo à disposição para dar dicas e responder dúvidas! 
																																																								<br/><br/>
																																																								<Link 
																																																									variant="action"
																																																									color="white"
																																																									textAlign='center'
																																																									justify='center'
																																																									href="https://nubank.zoom.us/webinar/register/WN_CGA5DAN-R7-sIS85ikP0bA"
																																																									>
																																																									Para participar, registre-se no Webinar aqui
																																																								</Link>
																																																							</Typography>


																																																						</Box>
																																																					</Box>
																																																				</Box>



																																																				{/* SECTION CAROUSEL TESTIMONIAL - removed for 2022 */}
																																																				{/*<SectionTestimonials />*/}




																																																				{/* SECTION FAQ */}

																																																				<SectionFAQ />


																																																				</NuDSProvider>
																																																				);

}

export default App;

import React, { useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { useSwipeable } from 'react-swipeable';
import Button from '@nubank/nuds-web/components/Button/Button';

import CarouselItem from './components/CarouselItem/CarouselItem';
import {
  CarouselContainer,
  CarouselWrapper,
  ControlsContainer,
  SwipeableArea,
} from './style';

function Carousel({
  animation,
  children,
  currentStep,
  showControls,
  cardWidth,
  controlsProps,
  onStepChanged,
  autoPlay,
  renderOnlySelected,
}) {
  const currentIndex = currentStep - 1 || 0;
  const totalItems = React.Children.count(children);

  const isPreviousDisabled = currentIndex === 0;
  const isNextDisabled = currentIndex === totalItems - 1;

  const changeActive = offset => {
    const changed = (currentStep || 0) + offset;
    const newStep = Math.max(changed, 1);

    onStepChanged(newStep > totalItems ? totalItems : newStep);
  };

  const handlePrevChange = () => {
    changeActive(-1, totalItems);
  };

  const handleNextChange = () => {
    changeActive(1, totalItems);
  };

  const renderChild = (child, selected) => {
    if (!renderOnlySelected) {
      return child;
    }

    return selected ? child : null;
  };

  const handleAutoPlay = () => {
    if (currentStep === totalItems) {
      return onStepChanged(1);
    }

    return handleNextChange();
  };

  useEffect(() => {
    if (autoPlay && !showControls) {
      setTimeout(handleAutoPlay, autoPlay);
    }
  }, [currentStep]);

  const swipeHandles = useSwipeable({
    onSwipedLeft: handleNextChange,
    onSwipedRight: handlePrevChange,
    trackMouse: true,
  });

  return (
    <SwipeableArea
          textAlign ="center"
      {...swipeHandles}
    >
      <CarouselWrapper>
        <CarouselContainer
          animation={animation}
          transform={`${currentIndex * -100}%`}
        >
          {React.Children.map(children, (child, index) => (
            <CarouselItem
              cardWidth={cardWidth}
              selected={index === currentIndex}
              aria-hidden={index !== currentIndex}
            >
              {renderChild(child, index === currentIndex)}
            </CarouselItem>
          ))}
        </CarouselContainer>
        {showControls && (
          <ControlsContainer
            alignItems="center" justifyContent="center">
            <Button
              {...controlsProps}
              onClick={handlePrevChange}
              disabled={isPreviousDisabled}
              iconProps={{ name: 'arrow-left' }}
            />
            <Button
              {...controlsProps}
              onClick={handleNextChange}
              disabled={isNextDisabled}
              iconProps={{ name: 'arrow-right' }}
            />
          </ControlsContainer>
        )}
      </CarouselWrapper>
    </SwipeableArea>
  );
}

Carousel.defaultProps = {
  animation: true,
  autoPlay: 0,
  showControls: true,
  cardWidth: 100,
  currentStep: 1,
  renderOnlySelected: false,
  onStepChanged: () => { },
  controlsProps: {
    variant: 'basic',
    styleVariant: 'black',
  },
  textAlign: 'center',
};

Carousel.propTypes = {
  animation: PropTypes.bool,
  autoPlay: PropTypes.number,
  cardWidth: PropTypes.number,
  children: PropTypes.node.isRequired,
  controlsProps: PropTypes.shape({
    styleVariant: PropTypes.string.isRequired,
    variant: PropTypes.string.isRequired,
  }),
  currentStep: PropTypes.number,
  onStepChanged: PropTypes.func,
  renderOnlySelected: PropTypes.bool,
  showControls: PropTypes.bool,
};

export default memo(Carousel);

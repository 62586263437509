import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { CarouselItemContent } from './style';

class CarouselItem extends PureComponent {
  render() {
    const { cardWidth, selected, children } = this.props;

    return (
      <CarouselItemContent flexBasis={`${cardWidth}%`} selected={selected}>
        {children}
      </CarouselItemContent>
    );
  }
}

CarouselItem.defaultProps = {
  selected: false,
  cardWidth: 100,
  children: undefined,
};

CarouselItem.propTypes = {
  cardWidth: PropTypes.number,
  children: PropTypes.node,
  selected: PropTypes.bool,
};

export default CarouselItem;